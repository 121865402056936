

import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';



import React from "react"
import i18next from '../i18n/config';
// import ImgHeader from "../img/A_glowna_1/tlo_glowna.jpg";
import ImgOurCoffeeBack from "../img/D_kawa_ziarnista_18/iko_powrot_nasze_kawy.png";


import Imgiko_design from "../img/icon/iko_design.png";
import Imgiko_opak from "../img/icon/iko_opak.png";


export default function Header(props) {



    return (
            <React.Fragment>
                <section id="design" class="design firstSection">
                    <h1>{i18next.t('DesignH1')}</h1>
                    <p>{i18next.t('DesignP')}</p>
                    <aside class="mininavi">
                        <div>
                            <Link to=
                                  {'/OurPackaging/Packaging/'}
                                  > <img loading="lazy" src={Imgiko_opak}
                                  alt="Instanta Design" />
                            <p>
                                {i18next.t('Packaging')}
                            </p>
                            </Link>
                        </div>
                        <div>
                            <a onClick={() => {
                                    window.history.back();

//                                    document.href = "/OurPackaging/Packaging";
//                                    document.referrer//zawsze to samo
//                                    history.length


                                   }}>
                                <img loading="lazy" src={ImgOurCoffeeBack} alt="Instanta Coffee Back Icon" />
                                <p>{i18next.t('DesignMiniMenu1')}</p>
                            </a>
                        </div>
                    </aside>

                </section>

            </React.Fragment>

            )


}
